import React, { useState, useEffect } from 'react';
import {
  Menu,
  X,
  Brain,
  Building,
  ArrowRight,
  Code,
  Database,
  Cloud,
  MailIcon, PhoneIcon
} from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from './components/Card';
import './index.css';
import logo from './img/logo-color.png';
import Projects from './pages/Projects';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
      <header
          className={`fixed w-full z-50 transition-all duration-300 ${
              scrolled ? 'bg-black shadow-lg' : 'bg-transparent'
          }`}
      >
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center h-20">
            <div className="flex items-center">
              <div className="relative">
                <img src={logo} alt="ByteBridge Solution" className="h-50 w-60" />
              </div>
            </div>

            <nav className="hidden md:flex space-x-8">
              {['Home', 'Services', 'About', 'Contact'].map((item) => (
                  <a
                      key={item}
                      href={`#${item.toLowerCase()}`}
                      className="relative text-white hover:text-orange-500 transition-colors duration-300 group"
                  >
                    {item}
                    <span className="absolute bottom-0 left-0 w-full h-0.5 bg-orange-500 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
                  </a>
              ))}
            </nav>

            <button className="md:hidden" onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? <X className="text-white" /> : <Menu className="text-white" />}
            </button>
          </div>

          {isOpen && (
              <div className="md:hidden bg-black/95 backdrop-blur-sm rounded-lg shadow-xl mt-2 p-4">
                <div className="space-y-3">
                  {['Home', 'Services', 'About', 'Contact'].map((item) => (
                      <a
                          key={item}
                          href={`#${item.toLowerCase()}`}
                          className="block px-4 py-2 text-white hover:bg-gray-800 hover:text-orange-500 rounded-lg transition-colors duration-300"
                      >
                        {item}
                      </a>
                  ))}
                </div>
              </div>
          )}
        </div>
      </header>
  );
};

const HeroSection = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
      <div id="home" className="relative pt-20 overflow-hidden bg-black text-white">
        {/* Animated background patterns */}
        <div className="absolute inset-0 -z-10">
          <div className="absolute top-0 right-0 w-96 h-96 bg-orange-500 rounded-full filter blur-3xl opacity-20 animate-blob"></div>
          <div className="absolute bottom-0 left-0 w-96 h-96 bg-gray-500 rounded-full filter blur-3xl opacity-20 animate-blob animation-delay-2000"></div>
        </div>

        <div className="container mx-auto px-4 py-32">
          <div className="max-w-5xl mx-auto">
            <div
                className={`text-center transform transition-all duration-1000 ${
                    isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
                }`}
            >
              <h1 className="text-6xl font-bold mb-6">Transforming Ideas into Digital Reality</h1>
              <p className="text-xl text-gray-400 mb-12 max-w-2xl mx-auto">
                We create innovative solutions that drive the future of technology and business success.
              </p>
              <div className="flex justify-center gap-6">
                <button className="bg-orange-500 text-white px-8 py-4 rounded-full hover:bg-orange-600 transition-colors duration-300 transform hover:scale-105 shadow-lg">
                  Get Started
                </button>
                <button className="bg-transparent text-white px-8 py-4 rounded-full border-2 border-white hover:bg-white hover:text-black transition-colors duration-300 transform hover:scale-105">
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Animated stats */}
        <div className="bg-gradient-to-r from-gray-800 to-gray-900 py-12 mt-12">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8 text-white text-center">
              {[
                { number: '10+', label: 'Satisfied Clients' },
                { number: '20+', label: 'Completed Projects' },
                { number: '5+', label: 'Skilled Experts' },
                { number: '1+', label: 'Years of Experience' }
              ].map((stat, index) => (
                  <div
                      key={index}
                      className="transform hover:scale-105 transition-transform duration-300"
                  >
                    <div className="text-4xl font-bold mb-2">{stat.number}</div>
                    <div className="text-gray-400">{stat.label}</div>
                  </div>
              ))}
            </div>
          </div>
        </div>
      </div>
  );
};

const ServicesSection = () => {
  const services = [
    {
      icon: <Cloud className="h-12 w-12 text-blue-500" />,
      title: 'Cloud Solutions',
      description: 'Scalable cloud infrastructure and migration services',
      features: ['AWS & Azure', 'Cloud Migration', 'Serverless', 'DevOps']
    },
    {
      icon: <Brain className="h-12 w-12 text-purple-500" />,
      title: 'AI Development',
      description: 'Custom AI solutions and machine learning integration',
      features: ['Machine Learning', 'Deep Learning', 'NLP', 'Computer Vision']
    },
    {
      icon: <Code className="h-12 w-12 text-green-500" />,
      title: 'Web Development',
      description: 'Modern web applications and responsive design',
      features: ['React', 'Node.js', 'Python', 'Java']
    },
    {
      icon: <Database className="h-12 w-12 text-red-500" />,
      title: 'API Integration',
      description: 'Seamless API development and integration services',
      features: ['RESTful APIs', 'GraphQL', 'Microservices', 'WebSockets']
    }
  ];

  return (
      <div id="services" className="py-24 bg-black text-white relative">
        <div className="absolute inset-0 bg-gradient-to-b from-black to-gray-900 opacity-50"></div>
        <div className="container mx-auto px-4 relative">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4">Our Services</h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Comprehensive IT solutions tailored to your business needs
            </p>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {services.map((service, index) => (
                <Card
                    key={index}
                    className="group hover:shadow-2xl transition-all duration-300 hover:scale-105 bg-gray-800 border-0 relative overflow-hidden"
                >
                  <div className="absolute inset-0 bg-gradient-to-br from-gray-700 to-gray-800 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                  <CardHeader className="relative">
                    <div className="mb-4 transform group-hover:scale-110 transition-transform duration-300">
                      {service.icon}
                    </div>
                    <CardTitle className="text-xl font-bold text-white">{service.title}</CardTitle>
                  </CardHeader>
                  <CardContent className="relative">
                    <p className="text-gray-400 mb-4">{service.description}</p>
                    <ul className="space-y-2">
                      {service.features.map((feature, i) => (
                          <li key={i} className="flex items-center text-sm text-gray-400">
                            <ArrowRight className="h-4 w-4 text-orange-500 mr-2" />
                            {feature}
                          </li>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
            ))}
          </div>
        </div>
      </div>
  );
};

// const ProjectsSection = () => {
//   const projects = [
//     {
//       title: 'AI Platform',
//       description: 'AI Platform for real-time data processing',
//       link: 'https://ai.bytebridgesolution.com',
//       icon: <Brain className="h-8 w-8 text-purple-500" />,
//       stats: { Users: '100+', Accuracy: '90%', Processing: 'Real-time' }
//     },
//     {
//       title: 'Information Portal',
//       description: 'Centralized information portal for documents',
//       link: 'https://info.bytebridgesolution.com',
//       icon: <FileText className="h-8 w-8 text-blue-500" />,
//       stats: { Documents: '500+', Uptime: '95%', Users: '200+' }
//     },
//     {
//       title: 'API Services',
//       description: 'API services for data integration',
//       link: 'https://api.bytebridgesolution.com',
//       icon: <Server className="h-8 w-8 text-green-500" />,
//       stats: { Endpoints: '10+', Response: '<500ms', Availability: '95%' }
//     },
//     {
//       title: 'Knowledge Base',
//       description: 'Knowledge base for articles and discussions',
//       link: 'https://note.bytebridgesolution.com',
//       icon: <MessageSquare className="h-8 w-8 text-red-500" />,
//       stats: { Articles: '100+', Languages: '2+', Contributors: '5+' }
//     }
//   ];
//
//   return (
//       <div id="projects" className="py-24 bg-white text-black relative">
//         <div className="absolute inset-0 bg-gradient-to-t from-gray-100 to-white opacity-50"></div>
//         <div className="container mx-auto px-4 relative">
//           <div className="text-center mb-16">
//             <h2 className="text-4xl font-bold mb-4">Our Projects</h2>
//             <p className="text-gray-600 max-w-2xl mx-auto">
//               Innovative solutions that drive business success
//             </p>
//           </div>
//           <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
//             {projects.map((project, index) => (
//                 <Card
//                     key={index}
//                     className="group hover:shadow-2xl transition-all duration-300 hover:-translate-y-2 bg-gray-100 border-0"
//                 >
//                   <CardHeader>
//                     <div className="mb-4 transform group-hover:scale-110 transition-transform duration-300">
//                       {project.icon}
//                     </div>
//                     <CardTitle className="text-xl font-bold text-gray-800">{project.title}</CardTitle>
//                   </CardHeader>
//                   <CardContent>
//                     <p className="text-gray-600 mb-6">{project.description}</p>
//                     <div className="grid grid-cols-2 gap-4 mb-6">
//                       {Object.entries(project.stats).map(([key, value]) => (
//                           <div
//                               key={key}
//                               className="text-center p-2 bg-white rounded-lg shadow-sm"
//                           >
//                             <div className="font-bold text-orange-500">{value}</div>
//                             <div className="text-sm text-gray-500">{key}</div>
//                           </div>
//                       ))}
//                     </div>
//                     <a
//                         href={project.link}
//                         className="inline-flex items-center text-orange-500 hover:text-orange-600 transition-colors group"
//                     >
//                       Explore Project
//                       <ChevronRight className="ml-1 h-4 w-4 transform group-hover:translate-x-1 transition-transform" />
//                     </a>
//                   </CardContent>
//                 </Card>
//             ))}
//           </div>
//         </div>
//       </div>
//   );
// };

const AboutSection = () => {
  const stats = [
    { label: 'Years Experience', value: '1+' },
    { label: 'Team Members', value: '5' },
    { label: 'Projects Completed', value: '10+' },
    { label: 'Success Rate', value: '95%' }
  ];

  return (
      <div id="about" className="py-24 bg-black text-white relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-gray-900 to-black opacity-50"></div>
        <div className="container mx-auto px-4 relative">
          <div className="max-w-5xl mx-auto">
            <h2 className="text-4xl font-bold text-center mb-16">About Us</h2>
            <div className="grid md:grid-cols-2 gap-16 items-center">
              <div className="relative">
                <div className="absolute inset-0 bg-gradient-to-br from-gray-800 to-gray-900 rounded-3xl transform rotate-6"></div>
                <div className="relative bg-gray-800 p-8 rounded-3xl shadow-xl">
                  <Building className="h-48 w-48 text-orange-400 mx-auto mb-8" />
                  <div className="grid grid-cols-2 gap-6 text-center">
                    {stats.map((stat, index) => (
                        <div key={index} className="p-4 bg-gray-700 rounded-xl">
                          <div className="text-2xl font-bold text-orange-500">{stat.value}</div>
                          <div className="text-gray-400">{stat.label}</div>
                        </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="space-y-6">
                <h3 className="text-2xl font-bold text-white">
                  Innovating Tomorrow's Technology Solutions
                </h3>
                <p className="text-gray-400">
                  ByteBridge Solution is a South African IT services provider, dedicated to delivering
                  cutting-edge technology solutions that drive business success. Our team of experts
                  combines deep technical knowledge with innovative thinking to create solutions that
                  transform businesses.
                </p>
                <div className="space-y-4">
                  {[
                    'Industry-leading expertise in AI and cloud solutions',
                    'Committed to delivering exceptional results',
                    'Customer-centric approach to every project',
                    'Continuous innovation and learning'
                  ].map((point, index) => (
                      <div key={index} className="flex items-center">
                        <ArrowRight className="h-5 w-5 text-orange-500 mr-2" />
                        <span className="text-gray-300">{point}</span>
                      </div>
                  ))}
                </div>
                <button className="bg-orange-500 text-white px-8 py-3 rounded-full hover:bg-orange-600 transition-colors duration-300 transform hover:scale-105 shadow-lg">
                  Learn More About Us
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

const ContactSection = () => {
  return (
      <div id="contact" className="py-24 bg-black text-black relative">

        <div className="container mx-auto px-4 relative">
          <h2 className="text-4xl text-white font-bold text-center mb-16">Contact Us</h2>
          <div className="max-w-4xl mx-auto">
            <Card className="border-0 shadow-xl hover:shadow-2xl transition-shadow duration-300">
              <CardContent className="p-8">
                <form className="space-y-6">
                  <div className="grid md:grid-cols-2 gap-6">
                    <div>
                      <label className="block text-sm font-medium text-white mb-2">Name</label>
                      <input
                          type="text"
                          className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500 transition-colors"
                          placeholder="Your name"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-white mb-2">Email</label>
                      <input
                          type="email"
                          className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500 transition-colors"
                          placeholder="your@email.com"
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-white mb-2">Subject</label>
                    <input
                        type="text"
                        className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500 transition-colors"
                        placeholder="How can we help?"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-white mb-2">Message</label>
                    <textarea
                        className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500 transition-colors h-32"
                        placeholder="Your message"
                    ></textarea>
                  </div>
                  <button className="w-full bg-orange-500 text-white px-8 py-4 rounded-full hover:bg-orange-600 transition-all duration-300 transform hover:scale-105 shadow-lg">
                    Send Message
                  </button>
                </form>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
  );
};

const Footer = () => (
    <footer className="bg-black text-white relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-black to-gray-900 opacity-50"></div>
      <div className="container mx-auto px-4 py-16 relative">
        <div className="grid md:grid-cols-4 gap-12">
          <div>
            <div className="flex items-center mb-6">
                <img src={logo} alt="ByteBridge Solution" className="h-20 w-50" />
            </div>
            <p className="text-gray-400">
              Transforming businesses through innovative technology solutions.
            </p>
            {/*<div className="mt-6 flex space-x-4">*/}
            {/*  {['twitter', 'linkedin', 'github', 'facebook'].map((social) => (*/}
            {/*      <a*/}
            {/*          key={social}*/}
            {/*          href="#"*/}
            {/*          className="w-10 h-10 rounded-full bg-gray-800 flex items-center justify-center hover:bg-orange-500 transition-colors duration-300"*/}
            {/*      >*/}
            {/*        <span className="sr-only">{social}</span>*/}
            {/*        <Globe className="h-5 w-5" />*/}
            {/*      </a>*/}
            {/*  ))}*/}
            {/*</div>*/}
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-6">Quick Links</h3>
            <ul className="space-y-4">
              {['Services', 'About', 'Contact'].map((item) => (
                  <li key={item}>
                    <a
                        href={`#${item.toLowerCase()}`}
                        className="text-gray-400 hover:text-orange-500 transition-colors duration-300"
                    >
                      {item}
                    </a>
                  </li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-6">Services</h3>
            <ul className="space-y-4">
              {['Cloud Solutions', 'AI Development', 'Web Development', 'API Integration'].map(
                  (service) => (
                      <li key={service}>
                        <a
                            href="#"
                            className="text-gray-400 hover:text-orange-500 transition-colors duration-300"
                        >
                          {service}
                        </a>
                      </li>
                  )
              )}
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-6">Contact</h3>
            <ul className="space-y-4">

                <li className="flex items-center text-gray-400">
                  <PhoneIcon className="h-5 w-5 mr-3 text-orange-500" />
                  +27 63-308-5555
                </li>
                <li className="flex items-center text-gray-400">
                  <MailIcon className="h-5 w-5 mr-3 text-orange-500" />
                  hujiahao@bytebridgesolution.com
                </li>
            </ul>
          </div>
        </div>

        <div className="border-t border-gray-800 mt-12 pt-8 text-center text-gray-400">
          <p>&copy; 2024 ByteBridge Solution. All rights reserved.</p>
        </div>
      </div>
    </footer>
);

const App = () => {
  return (
      <div className="min-h-screen">
        <Header />
        <main>
          <HeroSection />
          <ServicesSection />
          {/*<ProjectsSection />*/}
          <AboutSection />
          <ContactSection />
        </main>
        <Footer />
      </div>

  );
};

export default App;
